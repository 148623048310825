import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Notebook from '../../images/icons/notebook.svg'
import Chart from '../../images/icons/chart.svg'
import Shape from '../../images/icons/shape.svg'

import PrefabFeatures from "../../components/PrefabFeatures"
import LayoutNo from "../../components/LayoutNo"
import HeaderNo from '../../components/HeaderNo'
import Seo from "../../components/Seo"
import ContactLauriNo from "../../components/ContactLauriNo";

const Prefab = () => {
  return (
    <LayoutNo>

      <Seo title="Prefab" description="Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen" lang="no" />
      <HeaderNo />
      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Prefab</h1>
          <p className="body-l">Prefab is short for prefabrication and although it has many synonyms, it basically means adding value to a building offsite, usually in a factory environment.</p>
        </div>
      </div>

      <div className="wel-mb-l">
        <StaticImage
          src="../../images/prefab-1.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Prefab"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="w-2/3 mx-auto wel-mb-l">
        <h2 className="title-xs wel-mb-s">Methods of Prefab Value Creation</h2>
        <p className="body-l">The degree of prefab — how much value can or should be added offsite — can vary quite a lot and depends on the architectural characteristics, target cost, site distance from the factory etc. The highest degree of prefab can be achieved with a modular volumetric approach, but it poses strict limitations for design. A panelized prefab solution allows for more architectural freedom, but more work is done on site. </p>
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <PrefabFeatures />
        </div>
      </div>

      <div className="container-base">
        <div className="section-1-2-center">
          <h2 className="title wel-mb-s">Prefab house </h2>
          <p className="body-l">Prefab house or prefabricated house provide a unique solution for controlling quality in an improved and systemized way. The quality of onsite work is most often people-based, relying heavily on the craftsmanship of the site crews. A prefab house is made in a factory setting, where the weather is always the same and quality management is process-based. </p>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/prefab-2.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Prefab"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="container-base">
        <div className="flex justify-end wel-mb-l">
          <div className="sm:w-2/3 lg:w-1/2 text-left">
            <h2 className="title-xs wel-mb-xs">Building with prefab</h2>
            <p className="body-l wel-mb-s">Why is the construction industry talking about building with prefab and the use of timber so much recently? There are several reasons and they are strongly interlinked with each other. Firstly there is an increasing lack of skilled labour which affects productivity and quality. There is also a growing demand for housing yet at the same time there is a push to cut costs since young people just can’t afford to buy a home. And of course we can’t forget that the building industry is a huge contributor to the increasing global CO2 levels.</p>
            <p>Offsite construction allows for more control over the variables — quality, productivity, material logistics, waste management and energy consumption. It is the sum of all these parts that make building with prefab a modern method of construction. </p>
          </div>
        </div>
        <div className="flex justify-start">
          <div className="sm:w-2/3 lg:w-1/2 text-left">
            <h2 className="title-xs wel-mb-xs">Prefab walls</h2>
            <p className="body-l wel-mb-s">In theory every building can be made with prefab parts, the only question is whether it is reasonable and makes sense financially. Although Welement makes all the main structure types — walls, ceilings, roofs etc. — we often find the best scope of delivery together with our clients.</p>
            <p>Generally speaking an external timber frame prefab wall can be divided into 5 main parts: External facade layer, Wind barrier layer, Insulation/Main supportive frame layer, Vapour barrier layer, Inside decorative layer. This basic logic also applies to the roof structure, with the first layer being a weather proof final cover etc. </p>
          </div>
        </div>
      </div>



      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title wel-mb-s">Prefab house price</h2>
            <p className="body-l">The cost of a prefab house is mostly defined by the architectural form and details — long ceiling spans, type and size of windows, roof complexity and material choices. Here are the factores that shape the prefab house price: </p>
          </div>
          <div className="grid md:grid-cols-3 gap-12 md:gap-8 w-full sm:w-2/3 md:w-full mx-auto">
            <div className="text-center">
              <img src={Shape} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-xs">Architectural aspects</h3>
              <p>Architectural form<br />
                Material &amp; details<br />
                Design </p>
            </div>

            <div className="text-center">
              <img src={Chart} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-xs">Logistics &amp; supplychain</h3>
              <p>A significant part of the cost is related to services like logistics, design and assembly. </p>
            </div>

            <div className="text-center">
              <img src={Notebook} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-xs">The size &amp; number of prefab parts</h3>
              <p>For some sites building with modular units could be impossible because of lifting restrictions or site accessibility.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title wel-mb-s">Prefab construction</h2>
            <p className="body-l">Prefab construction is not a new invention and has been around for over a century since Bauhaus and their contemporaries envisioned the first concepts of industrializing construction and architecture. Looking at prefab construction from just the perspective of productivity is too narrow. By shifting the commonly used supply chain setup, it also disruptis how construction perceives planning, decision management, logistics and consistency of quality.</p>
          </div>
          <div className="w-2/3 mx-auto grid grid-cols-2 gap-8">
            <div className="text-center">
              <h4 className="title-xs wel-mb-xs">Project<br /> types</h4>
              <p>
                Apartment buildings<br />
                Rowhouses<br />
                Private houses<br />
                Facade systems
              </p>
            </div>

            <div className="text-center">
              <h4 className="title-xs wel-mb-xs">Prefab manufacturer<br />
                capabilities</h4>
              <p>
                Terrace &amp; balcony<br />
                Roof elements<br />
                Ceiling elements<br />
                Floor elements<br />
                Internal wall elements<br />
                Facade &amp; external wall elements
              </p>
            </div>
          </div>
        </div>
      </div>

      <ContactLauriNo />

    </LayoutNo>
  )

};

export default Prefab;
